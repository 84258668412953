<template>
  <Box>
  
    <SpacerRow />
  
    <ErrorRow 
      :error="error" />
  
    <SuccessRow 
      :message="message" />

    <Menu page="license"/>
    
    <TitleRow>
      Update License
    </TitleRow>

    <TitleRow :subtitle="true" align="left"> Drivers License </TitleRow>
    <Row>
      <Column :width="15">
        <Box >
          <KeyList
            :title="UserFields.LICENSE_CLASS"
            placeholder="License Class"
            :defaultValue="licenseClass"
            :options="licenseClassNames"
            hint="Driver's license class will be used by the mobile app to help with weight compliance"
            v-on:value="withLicenseClass"
          />
        </Box>
        <Spacer />
        <Box>
          <KeyDate 
            :title="UserFields.LICENSE_EXPIRY"
            placeholder="Expires" 
            :defaultValue="licenseExpiry" 
            v-on:value="withValue" />
        </Box>
      </Column>
      
    </Row>
    
    <SaveRow
      :left="5" :right="5"
      :disabled="disableAll" v-on:value="save" />

  </Box>
</template>
<script>
import { mapGetters } from 'vuex'

import ConstUtils   from "@/utils/ConstUtils.js";
import ContentUtils   from "@/utils/ContentUtils.js";
import StringUtils   from "@/utils/StringUtils.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import SpacerRow from '@/portals/shared/library/spacer/SpacerRow.vue';
import Spacer from '@/portals/shared/library/spacer/Spacer.vue';

import SaveRow  from "@/components/row/SaveRow.vue";
import SuccessRow from "@/components/row/SuccessRow.vue";
import ErrorRow   from "@/components/row/ErrorRow.vue";

import KeyList from '@/components/input/KeyList.vue';
import KeyDate from '@/components/input/KeyDate.vue';
import User from "@/domain/model/user/User.js";
import Menu  from './Menu.vue';

export default {
  name: "portal-customer-operator-employee-file",
  components: {
    Box,Row,Column,
    TitleRow, SpacerRow, SaveRow, Spacer,
    KeyList, KeyDate, Menu,
    ErrorRow, SuccessRow,
  },
  data() {
    return {
      id: this.$route.params["id"],
      disableAll: false,
      Constants: ConstUtils,
      Contents: ContentUtils,
      Strings: StringUtils,
      licenseClass: null,
      licenseExpiry: null,
      message: null,
      error: null,
      user_kvps: {},
      employee_kvps: {},
      UserFields: User.FIELDS,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
    userData: function() {
      return this.user.data;
    },
    user: function() {
      return this.employee.user().find();
    },
    
    employeeData: function() {
      return this.employee.data;
    },
    employee: function() {
      return this.domain.employees().findById(this.id);
    },
    licenseClassNames: function() {
      return ContentUtils.LICENSES();
    },
  },
  watch: {
    
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN, });
    }
    this.licenseClass = this.user.licenseClass();
    this.licenseExpiry = this.user.licenseExpiry();
  },
  methods: {
    withLicenseClass: function(value) {
      this.withValue(value);
    },
    withValue: function (kvp) {
      this.error = null;
      this.message = null;
      ContentUtils.set(this.user_kvps, kvp, this.domain.logger());
    },
    save: function () {
      this.error = null;
      this.message = null;
      this.disableAll = true;
      this.isSaving = true;
      
      this.employee_kvps[ConstUtils.FIELDS.ID] = this.employee.id();
      this.user_kvps[ConstUtils.FIELDS.ID] = this.user.id();

      var event = this.domain.events().userSave(this.user_kvps, this.employee_kvps);
      event.send(this.listener);
    },
    listener: function(event) {
      this.isSaving = false;
      this.disableAll = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        window.scrollTo(0, 0);
        this.message = "User changes have been succuessfully saved!";
        this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.EMPLOYEES });
      }
    },
  },
}
</script>